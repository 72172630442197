@use "../../styles/typography"
  
.footer
  color: #FFFFFFAA

  &__logo
    flex: 0 0 48px
    margin-bottom: 10px
    @media screen and (min-width: 992px)
      margin: 20px 20px 0 0

  &__wrapper
    position: relative
    display: flex
    padding: 20px 40px 5px
    background-color: #1E1E1E
    flex-wrap: wrap
    @media screen and (min-width: 992px)
      flex-wrap: nowrap
      padding-bottom: 40px

  &__intro
    flex: 0 0 100%
    @media screen and (min-width: 992px)
      flex: 0 0 300px
      margin-right: 80px
      .expand__title
        display: none

  &__contact
    flex: 0 0 100%
    @media screen and (min-width: 992px)
      flex: 0 1 auto
      display: flex
      flex-wrap: wrap
      p
        flex: 0 1 300px
        margin-right: 80px

  &__contact-way
      p
        display: flex
        align-items: center
      svg
        margin-right: 10px

  &__social, &__download
    margin-bottom: 20px
    @media screen and (min-width: 992px)
      margin-bottom: 0
    svg
      cursor: pointer
      & + svg
        margin-left: 8px

  &__download
    @media screen and (min-width: 992px)
      position: absolute
      bottom: 20px
      left: 40px

  &__social
    position: absolute
    top: 30px
    right: 40px
    @media screen and (min-width: 992px)
      top: 20px

  &__copyright
    flex: 1 0 100%
    text-align: center
    padding: 12px 0
    background-color: #2A2A2A


.expand
  + .expand
    border-top: 1px solid #FFFFFF22
    @media screen and (min-width: 992px)
      border: 0

  &__content
    max-height: 0px
    overflow: hidden
    transition: .2s
    @media screen and (min-width: 992px)
      display: flex
      max-height: 200px

  &__title
    display: flex
    justify-content: space-between
    cursor: pointer
    padding: 10px 0
    h4
      margin-bottom: 0
    svg
      cursor: pointer
      transition: transform .2s
      @media screen and (min-width: 992px)
        display: none
    input[type="checkbox"]
      display: none
    input:checked ~ svg
        transform: rotateX(180deg)
    &:has(input:checked) ~ .expand__content
      max-height: 200px
    @media screen and (min-width: 992px)
      cursor: default
      padding: 0
