.player

  &__title
    font-size: 24px
    font-family: 'Saira Semi Condensed', sans-serif
    font-weight: 600
    text-align: center
    @media screen and (min-width: 992px)
      font-size: 60px

  &__banner
    text-align: center
    background: url(../../assets/images/Banner_player.png),
    background-repeat: no-repeat
    background-size: cover
    background-position: cneter center
    height: 35vh
    padding: 57px 84px
    h4
      font-family: 'Saira Semi Condensed', sans-serif
      font-weight: 600
      font-size: 24px
    p
      font-size: 12px
    button
      background-color: #A2000F
      color: #FFFFFFCC
      font-family: 'Saira Semi Condensed', sans-serif
      font-weight: 600
      font-size: 20px
    @media screen and (min-width: 992px)
      height: 900px
      padding: 240px 27%
      h4
        font-size: 60px
      p
        font-size: 24px
      button
        width: 343px
        padding: 8px 0
      img
        width: 280px

  &__howto
    padding: 24px 5% 36px

  &__video
    width: fit-content
    margin: 0 auto 40px
    iframe
      width: 360px
      height: 202px
    @media screen and (min-width: 576px)
      margin-bottom: 60px
      iframe
        width: 500px
        height: 281px
    @media screen and (min-width: 992px)
      margin-bottom: 80px
      iframe
        width: 917px
        height: 515px

  &__step
    min-width: 369px
    max-width: 420px
    margin: 0 auto
    padding: 15px 24px 66px
    overflow: hidden
    .item
      position: relative
      display: flex
      align-items: center
      border-radius: 9999px
      padding: 8px 0
      &:after
        content: ''
        display: block
        position: absolute
        bottom: -8px
        left: 52px
        width: calc(100% - 105px)
        height: 16px
        background-color: #A2000F
      &:nth-child(even)
        .image
          order: 1
        .des
          text-align: right
          margin-right: 8px
        &:before
          content: ''
          display: block
          position: absolute
          right: -88px
          width: 144px
          height: 144px
          background-color: #A2000F
          clip-path: circle(50.0% at 0 50%)
      &:nth-child(odd)
        .des
          text-align: left
          margin-left: 8px
        &:before
          content: ''
          display: block
          position: absolute
          left: -88px
          width: 144px
          height: 144px
          background-color: #A2000F
          clip-path: circle(50.0% at 100% 50%)
      &:last-child
        &:after
          display: none
    .image
      flex: 0 0 112px
      display: flex
      align-items: center
      justify-content: center
      overflow: hidden
      height: 112px
      border-radius: 60px
      z-index: 2
      img
        width: 113%
    .des
      h5
        font-size: 20px
        font-weight: 600
        margin-bottom: 0
      p
        font-size: 12px
        line-height: 14px
        margin-bottom: 0
    @media screen and (min-width: 992px)
      max-width: 710px
      padding: 15px 30px 66px
      overflow: visible
      .item
        padding: 14px 0
        &:after
          bottom: -14px
          left: 90px
          width: calc(100% - 186px)
          height: 28px
          background-color: #A2000F
        &:nth-child(even)
          .des
            transform: translateX(-50%)
            h5
              transform: translateX(45%)
          &:before
            right: -155px
            width: 252px
            height: 252px
        &:nth-child(odd)
          .des
            margin-left: 24px
          &:before
            left: -155px
            width: 252px
            height: 252px
      .image
        flex: 0 0 196px
        height: 196px
        border-radius: 110px
      .des
        h5
          font-size: 32px
        p
          width: 145%
          font-size: 24px
          line-height: 34px