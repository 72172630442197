.faq
  padding: 80px 4% 40px
  text-align: center
  @media screen and (min-width: 992px)
    padding: 150px 5% 40px
  &__wrapper
    position: relative
    padding: 75px 4% 120px
    background-color: #232323
    border-radius: 8px
    h2
      font-family: 'Saira Semi Condensed', sans-serif
      font-weight: 600
      font-size: 24px
    @media screen and (min-width: 992px)
      padding: 135px 5% 80px
      h2
        font-size: 60px
  &__logo
    position: absolute
    top: -150px
    left: 50%
    right: 0
    transform: translateX(-50%) scale(.5)
    @media screen and (min-width: 992px)
      transform: translateX(-50%) scale(1)
  &__item
    margin-bottom: 26px
    h4
      font-size: 20px
      @media screen and (min-width: 992px)
        font-size: 32px
    p
      font-size: 12px
      color: #FFFFFFAA
      @media screen and (min-width: 992px)
        font-size: 20px
    a
      display: flex
      align-items: center
      justify-content: center
      font-size: 14px
      color: #DC4636
      svg
        margin-left: 5px
      &:hover
        color: #DC4636
      @media screen and (min-width: 992px)
        font-size: 20px
  &__download
    svg + svg
      margin-left: 12px
    svg.web
      display: none
    @media screen and (min-width: 992px)
      svg.web
        display: inline-block
      svg.mobile
        display: none
    
