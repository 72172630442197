.pools
  padding: 24px 16px
  @media screen and (min-width: 992px)
    position: relative
    display: flex
    align-items: center
    margin: 0 150px 0 96px
    padding: 0

  &__menu
    margin-bottom: 20px
    @media screen and (min-width: 992px)
      flex: 0 0 200px
      margin-right: 100px
      margin: auto 100px auto 0
    .dropdown
      &__title
        display: flex
        justify-content: space-between
        padding: 5px
        color: #FFFFFFCC
        cursor: pointer
        border-bottom: 1px solid #FFFFFF22
        @media screen and (min-width: 992px)
          font-size: 20px

  &__list
    flex-grow: 1
    overflow: auto
    scroll-behavior: smooth
    &::-webkit-scrollbar
      display: none
    .ant-empty
      margin: 16px 0
      transform: translateX(-20px)
    .ant-empty-image
      height: 60px
    .ant-empty-description
      color: #FFFFFFAA
    .list
      display: flex
      width: fit-content
    .league, .games, .host, .team
      display: flex
      justify-content: center
    .game
      width: 163px
      padding: 12px 0
      text-align: center
      background-color: #232323
      border: 1px solid #FFFFFF22
      cursor: pointer
      @media screen and (min-width: 992px)
        border-top: 0
        border-bottom: 0
    .logo
      align-self: center
      margin: 0 16px
      img
        height: 60px
    .time
      font-family: 'Saira Semi Condensed'
      font-size: 20px
      font-weight: 600
      color: #DC4636
    .host
      font-size: 12px
      color: #FFFFFFAA
      margin-bottom: 16px
    .team
      height: 41px
      align-items: center
      + .team
        margin-top: 8px
      img, svg
        margin-right: 8px
        width: 40px
    .name
      color: #FFFFFFCC
      font-weight: 600
      margin-bottom: 0
    .controller
      display: none
      @media screen and (min-width: 992px)
        position: absolute
        top: 50%
        transform: translateY(-50%)
        z-index: 9
        display: flex
        align-items: center
        justify-content: center
        width: 40px
        height: 40px
        border-radius: 20px
        background-color: #FFFFFF22
        cursor: pointer
        &.prev
          left: 240px
          svg
            transform: rotate(90deg) scale(1.5)
        &.next
          right: -80px
          svg
            transform: rotate(270deg) scale(1.5)

.ant-dropdown-menu.ant-dropdown-menu-root
  background-color: #363636
  max-height: 200px
  overflow: auto
.ant-dropdown-menu-item.ant-dropdown-menu-item-only-child
  .ant-dropdown-menu-title-content
    color: #FFFFFFCC
    &:hover
      background-color: #363636
    & + .ant-dropdown-menu-item.ant-dropdown-menu-item-only-child
      border-top: 1px solid #FFFFFF22
