.home
  &__title
    font-size: 24px
    font-family: 'Saira Semi Condensed', sans-serif
    font-weight: 600
    @media screen and (min-width: 992px)
      font-size: 60px

  &__banner
    padding: 0 6%
    background: url(../../assets/images/Banner_home.png), url(../../assets/images/Banner_football.png)
    background-repeat: no-repeat
    background-size: 100%
    &-wrapper
      display: flex
      align-items: center
      max-width: 1600px
      height: 62vw
      margin: 0 auto
    .intro
      text-align: right
    .download
      display: none
    h1
      font-size: 24px
      font-family: 'Saira Semi Condensed', sans-serif
      font-weight: 600
      margin-bottom: 0
    h4
      font-size: 16px
      font-family: 'Saira Semi Condensed', sans-serif
      font-weight: 600
    p
      font-size: 12px
    img
      width: 120px
    @media screen and (min-width: 576px)
      img
        width: 180px
    @media screen and (min-width: 768px)
      img
        width: 240px
      h1
        font-size: 32px
      h4
        font-size: 24px
      p
        font-size: 14px
    @media screen and (min-width: 992px)
      h1
        font-size: 60px
      h4
        font-size: 40px
      p
        font-size: 24px
      img
        width: 280px
        margin-left: 50px
    @media screen and (min-width: 1280px)
      img
        width: 30%
        margin-left: 80px
      .download
        display: inline-block
        svg + svg
          margin-left: 16px

  &__start
    background-image: url(../../assets/images/Howto_bg_mobile.png)
    background-size: cover
    background-repeat: no-repeat
    text-align: center
    padding: 40px 0
    @media screen and (min-width: 992px)
      padding: 40px 0 108px
  &__video
    width: fit-content
    margin: 0 auto 24px
    iframe
      width: 360px
      height: 202px
    @media screen and (min-width: 576px)
      iframe
        width: 500px
        height: 281px
    @media screen and (min-width: 992px)
      margin-bottom: 32px
      iframe
        width: 917px
        height: 515px
  
  &__step
    .item
      margin-bottom: 24px
      padding: 0 16px
      img
        width: 112px
        margin-bottom: 8px
      h5
        font-size: 20px
        margin-bottom: 5px
      p
        font-size: 12px
        margin-bottom: 5px
    @media screen and (min-width: 992px)
      display: flex
      justify-content: space-evenly
      .item
        flex: 0 0 290px
        margin-bottom: 0px
        img
          width: 220px
        h5
          font-size: 32px
        p
          font-size: 20px
  
  &__spotlight
    background-image: url('../../assets/images/Banner_home_footer.png')
    background-size: cover
    background-position: center center
    padding: 16px
    text-align: center
    p
      max-width: 500px
      margin: 0 auto 32px
    button
      width: 120px
      background-color: #A2000F
      color: #FFFFFFCC
      font-weight: 600
    @media screen and (min-width: 992px)
      height: 600px
      padding: 158px 23% 0
      h4
        font-size: 60px
      p
        font-size: 24px
        margin-bottom: 32px
      button
        width: 343px
        padding: 10px
        font-size: 20px
        font-family: 'Saira Semi Condensed', sans-serif
        font-weight: 600


  &__marquee
    position: relative
    overflow: hidden
    height: 38px
    line-height: 38px
    background-color: #3A559F
    color: #FFFFFFDD

    .marquee
      display: inline-block
      white-space: nowrap
      transform: translateX(100%)
      animation-name: marquee
      animation-timing-function: linear
      animation-iteration-count: infinite
      // animation-duration: 18s
      span + span
        margin-left: 400px

    @keyframes marquee
      0%
        transform: translateX(100%)
      100%
        transform: translateX(-100%)
    @media screen and (min-width: 992px)
      height: 42px
      line-height: 42px
      font-size: 20px