.host
  text-align: center
  overflow: hidden

  &__title
    font-size: 24px
    font-family: 'Saira Semi Condensed', sans-serif
    font-weight: 600
    @media screen and (min-width: 992px)
      font-size: 60px

  &__banner
    padding: 0 6%
    text-align: left
    background: url(../../assets/images/Banner_host.png), url(../../assets/images/Banner_NFL.png)
    background-repeat: no-repeat
    background-size: 100%
    &-wrapper
      display: flex
      align-items: center
      max-width: 1600px
      height: 62vw
      margin: 0 auto
    h4
      font-family: 'Saira Semi Condensed', sans-serif
      font-weight: 600
      font-size: 24px
    p
      font-size: 12px
    button
      background-color: #A2000F
      color: #FFFFFFCC
      font-weight: 600
      font-family: 'Saira Semi Condensed', sans-serif
    img
      width: 120px
    @media screen and (min-width: 576px)
      img
        width: 180px
    @media screen and (min-width: 992px)
      h4
        font-size: 60px
      p
        font-size: 24px
      button
        width: 343px
        padding: 8px 0
        font-size: 20px
      img
        width: 280px
    @media screen and (min-width: 1280px)
      img
        width: 30%

  &__howto
    padding: 40px 5%

  &__video
    width: fit-content
    margin: 0 auto 80px
    iframe
      width: 360px
      height: 202px
    @media screen and (min-width: 576px)
      margin-bottom: 100px
      iframe
        width: 500px
        height: 281px
    @media screen and (min-width: 992px)
      margin-bottom: 190px
      iframe
        width: 917px
        height: 515px

  &__board
    position: relative
    background-color: #232323
    color: #FFFFFFCC
    font-weight: 600
    margin-bottom: 80px
    padding: 72px 6% 24px
    border-radius: 8px
    text-align: left
    &:last-child
      margin-bottom: 0
    img, svg
      position: absolute
    img
      height: 112px
      top: -60px
      left: 50%
      transform: translateX(-50%)
    svg
      top: -150px
      left: 50%
      transform: translateX(-50%) scale(.5)
    .line
      display: flex
      justify-content: space-between
    .icons
      top: -100px
    @media screen and (min-width: 992px)
      width: 78%
      max-width: 1120px
      margin: 0 auto 150px
      padding: 134px 20% 80px
      font-size: 32px
      font-weight: 600
      img
        height: 220px
        top: -115px
      svg
        top: -150px
        transform: translateX(-50%) scale(1)
      .icons
        top: -100px
  &__offer
    margin-bottom: 40px
    p
    .circle
      display: inline-block
      text-align: center
      width: 112px
      height: 112px
      border-radius: 60px
      background-color: #A2000F
      color: #FFFFFFCC
      font-family: 'Saira Semi Condensed', sans-serif
      font-weight: 600
      font-size: 24px
      line-height: 112px
      margin-bottom: 16px
      + .circle
        margin-left: 13px
    @media screen and (min-width: 992px)
      h3
        margin-bottom: 0
      p
        font-size: 24px
        margin-bottom: 40px
      .circle
        width: 220px
        height: 220px
        border-radius: 110px
        line-height: 220px
        font-size: 60px
        margin-bottom: 120px
        + .circle
          margin-left: 120px

  &__step
    min-width: 369px
    max-width: 420px
    margin: 0 auto
    padding: 15px 24px 66px
    overflow: hidden
    .item
      position: relative
      display: flex
      align-items: center
      border-radius: 9999px
      padding: 8px 0
      &:after
        content: ''
        display: block
        position: absolute
        bottom: -8px
        left: 52px
        width: calc(100% - 105px)
        height: 16px
        background-color: #A2000F
      &:nth-child(even)
        .image
          order: 1
        .des
          text-align: right
          margin-right: 8px
        &:before
          content: ''
          display: block
          position: absolute
          right: -88px
          width: 144px
          height: 144px
          background-color: #A2000F
          clip-path: circle(50.0% at 0 50%)
      &:nth-child(odd)
        .des
          text-align: left
          margin-left: 8px
        &:before
          content: ''
          display: block
          position: absolute
          left: -88px
          width: 144px
          height: 144px
          background-color: #A2000F
          clip-path: circle(50.0% at 100% 50%)
      &:last-child
        &:after
          display: none
    .image
      flex: 0 0 112px
      display: flex
      align-items: center
      justify-content: center
      overflow: hidden
      height: 112px
      border-radius: 60px
      z-index: 2
      img
        width: 113%
    .des
      h5
        font-size: 20px
        font-weight: 600
        margin-bottom: 0
      p
        font-size: 12px
        line-height: 14px
        margin-bottom: 0
    @media screen and (min-width: 992px)
      max-width: 710px
      padding: 15px 30px 66px
      overflow: visible
      .item
        padding: 14px 0
        &:after
          bottom: -14px
          left: 90px
          width: calc(100% - 186px)
          height: 28px
          background-color: #A2000F
        &:nth-child(even)
          .des
            transform: translateX(-50%)
            h5
              transform: translateX(45%)
          &:before
            right: -155px
            width: 252px
            height: 252px
        &:nth-child(odd)
          .des
            margin-left: 24px
          &:before
            left: -155px
            width: 252px
            height: 252px
      .image
        flex: 0 0 196px
        height: 196px
        border-radius: 110px
      .des
        h5
          font-size: 32px
        p
          width: 145%
          font-size: 24px
          line-height: 34px

  &__idea
    padding: 20px 16px 20px 150px
    background: url(../../assets/images/Banner_footer.png)
    background-size: cover
    text-align: right
    p
      margin-bottom: 0
    @media screen and (min-width: 992px)
      padding: 198px 80px
      p
        width: 640px
        margin-left: auto
        mergin-right: 0
        font-size: 24px
