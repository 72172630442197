.App {
  font-family: 'PingFag SC', sans-serif;
  body {
    color: #FFFFFFAA;
  }
  h1, h2, h3, h4, h5, h6 {
    color: #FFFFFFCC;
  }
  p {
    color: #FFFFFFAA
  }
}

@mixin H1 {
  font-size: 48px;
  line-height: 56px;
  font-weight: 600;
}

@mixin H2 {
  font-size: 40px;
  line-height: 45px;
  font-weight: 600;
}

@mixin H3 {
  font-size: 32px;
  line-height: 33px;
  font-weight: 600;
}

@mixin H4 {
  font-size: 24px;
  line-height: 28px;
  font-weight: 600;
}

@mixin H5 {
  font-size: 20px;
  line-height: 22px;
  font-weight: 600;
}

@mixin H6 {
  font-size: 16px;
  line-height: 19px;
  font-weight: 600;
}

@mixin H7 {
  font-size: 15px;
  line-height: 17px;
  font-weight: 600;
}

@mixin T1 {
  font-size: 60px;
  line-height: 56px;
  font-weight: 600;
}

@mixin T2 {
  font-size: 48px;
  line-height: 45px;
  font-weight: 600;
}

@mixin T3 {
  font-size: 40px;
  line-height: 33px;
  font-weight: 600;
}

@mixin T4 {
  font-size: 32px;
  line-height: 28px;
  font-weight: 600;
}

@mixin T5 {
  font-size: 24px;
  line-height: 22px;
  font-weight: 600;
}

@mixin T6 {
  font-size: 20px;
  line-height: 19px;
  font-weight: 600;
}

@mixin T7 {
  font-size: 16px;
  line-height: 17px;
  font-weight: 600;
}

@mixin P1 {
  font-size: 24px;
  line-height: 33px;
  font-weight: 400;
}

@mixin P2 {
  font-size: 20px;
  line-height: 28px;
  font-weight: 400;
}

@mixin P3 {
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
}

@mixin P4 {
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
}

@mixin P5 {
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
}