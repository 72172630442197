
.header
  position: -webkit-sticky
  position: sticky
  top: 0
  background-color: #1E1E1E
  color: #FFFFFFAA
  z-index: 999

  &__wrapper
    display: flex
    justify-content: space-between
    align-items: center
    flex-wrap: wrap
    padding: 6px 20px
    border-bottom: 1px solid #FFFFFF22
    @media screen and (min-width: 992px)
      padding: 6px 80px
    &:has(input:checked) ~ .header__menu
      transform: translateY(0)
      z-index: 1

  &__logo
    cursor: pointer

  &__tabs
    display: none
    .tab__item
      cursor: pointer
      padding: 10px
      color: #FFFFFFAA
    @media screen and (min-width: 992px)
      display: flex

  &__menu
    position: fixed
    top: 61px
    bottom: 0
    left: 0
    right: 0
    flex: 1 0 100%
    height: 100vh
    list-style-type: none
    padding: 30px 10px
    background-color: #1E1E1E
    transform: translateY(-120%)
    a
      display: block
      padding: 8px
      border-bottom: 1px solid #FFFFFF22
      cursor: pointer
      color: #FFFFFFAA
    @media screen and (min-width: 992px)
      display: none

    &-button
      display: inline-block
      cursor: pointer
      svg
        width: 24px
        margin-top: 8px
      input[type="checkbox"]
        display: none
      input:not(:checked) ~ .cancel
        display: none
      input:checked ~ .menu
        display: none

      @media screen and (min-width: 992px)
        display: none

  &__download
    background-color: #A2000F
    &.web
      display: none
    &.mobile
      display: flex
      align-items: center
      justify-content: center
      width: 40px
      height: 40px
      border-radius: 9999px
    @media screen and (min-width: 992px)
      &.web
        display: block
      &.mobile
        display: none

button
  padding: 5px 12px
  border: 0
  border-radius: 8px
  cursor: pointer