@import 'antd/dist/reset.css'

.App
  main
    background-color: #000
    min-height: calc(100vh - 290px)

.scroll-off
  overflow: hidden

.d-none
  display: none
