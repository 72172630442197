.modal
  position: fixed
  top: 0
  bottom: 0
  left: 0
  right: 0
  z-index: 999999
  background-color: #1E1E1E
  @media screen and (min-width: 768px)
    background-color: #00000088
  &__wrapper
    @media screen and (min-width: 768px)
      position: absolute
      top: 50%
      left: 50%
      transform: translate(-50%, -50%)
      width: 70%
      max-width: 700px
      height: fit-content
      background-color: #363636
      border-radius: 24px
  &__header
    display: flex
    align-items: center
    font-size: 24px
    border-bottom: 1px solid #FFFFFF22
    padding: 15px 24px
    h4
      flex-grow: 1
      text-align: center
      margin-bottom: 0
      margin-left: -24px
    svg
      order: -1
      cursor: pointer
    @media screen and (min-width: 768px)
      svg
        order: 1
      h4
        margin-left: 24px

  &__body
    padding: 50px 25px
    text-align: center
    .qrcode
      margin: 0 auto 24px
      background-color: #FFFFFF
      width: fit-content
      padding: 50px
      svg
        vertical-align: top
        transform: scale(1.3)
    p
      font-family: 'Saira Semi Condensed', sans-serif
      font-weight: 600
      font-size: 24px
      color: #FFFFFFCC !important
      @media screen and (min-width: 768px)
        font-size: 32px
    button
      width: 343px
      font-family: 'Saira Semi Condensed', sans-serif
      font-weight: 600
      font-size: 20px
      padding: 8px
      background-color: #A2000F
      color: #FFFFFFCC
